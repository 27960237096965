import moment from 'moment'

const diffDates = (firstDate: string, secondDate: string) => {
  return moment(secondDate).diff(firstDate, 'days');
}

const normalizeDate = (date: any) => {
  return moment(date).format('YYYY-MM-DD')
}

const areSameDate = (firstDate:string, secondDate:string) => {
  const diffedDates = diffDates(normalizeDate(firstDate), normalizeDate(secondDate));
  return (diffedDates === 0);
}

const serializeData = (data:any) => {
  try {
    return JSON.parse(JSON.stringify(data));
  } catch (e) {
    return data;
  }
}

const countDays = (startDate:string, endDate:string) => {
//   return parseInt(moment(startDate).diff(endDate, 'days'));
}

const teams = {
    "1": {
      "abbreviation": "NJD",
      "id": 1,
      "name": "New Jersey Devils",
      "teamName": "Devils",
      "primaryColor": "#ce1126",
      "secondaryColor": "#000000"
    },
    "2": {
      "abbreviation": "NYI",
      "id": 2,
      "name": "New York Islanders",
      "teamName": "Islanders",
      "primaryColor": "#00539b",
      "secondaryColor": "#f47d30",
    },
    "3": {
      "abbreviation": "NYR",
      "id": 3,
      "name": "New York Rangers",
      "teamName": "Rangers",
      "primaryColor": "#0038a8",
      "secondaryColor": "#ce1126",
    },
    "4": {
      "abbreviation": "PHI",
      "id": 4,
      "name": "Philadelphia Flyers",
      "teamName": "Flyers",
      "primaryColor": "#f74902",
      "secondaryColor": "#000"
    },
    "5": {
      "abbreviation": "PIT",
      "id": 5,
      "name": "Pittsburgh Penguins",
      "teamName": "Penguins",
      "primaryColor": "#000",
      "secondaryColor": "#fcb514"
    },
    "6": {
      "abbreviation": "BOS",
      "id": 6,
      "name": "Boston Bruins",
      "teamName": "Bruins",
      "primaryColor": "#000000",
      "secondaryColor": "#FFB81C"
    },
    "7": {
      "abbreviation": "BUF",
      "id": 7,
      "name": "Buffalo Sabres",
      "teamName": "Sabres",
      "primaryColor": "#003087",
      "secondaryColor": "#FFB81C"
    },
    "8": {
      "abbreviation": "MTL",
      "id": 8,
      "name": "Montréal Canadiens",
      "teamName": "Canadiens",
      "primaryColor": "#c51230",
      "secondaryColor": "#192168"
    },
    "9": {
      "abbreviation": "OTT",
      "id": 9,
      "name": "Ottawa Senators",
      "teamName": "Senators",
      "primaryColor": "#e31837",
      "secondaryColor": "#111"
    },
    "10": {
      "abbreviation": "TOR",
      "id": 10,
      "name": "Toronto Maple Leafs",
      "teamName": "Maple Leafs",
      "primaryColor": "#003e7e",
      "secondaryColor": "#fff"
    },
    "12": {
      "abbreviation": "CAR",
      "id": 12,
      "name": "Carolina Hurricanes",
      "teamName": "Hurricanes",
      "primaryColor": "#b72b35",
      "secondaryColor": "#111"
    },
    "13": {
      "abbreviation": "FLA",
      "id": 13,
      "name": "Florida Panthers",
      "teamName": "Panthers",
      "primaryColor": "#041e42",
      "secondaryColor": "#c8102e"
    },
    "14": {
      "abbreviation": "TBL",
      "id": 14,
      "name": "Tampa Bay Lightning",
      "teamName": "Lightning",
      "primaryColor": "#002868",
      "secondaryColor": "#000"
    },
    "15": {
      "abbreviation": "WSH",
      "id": 15,
      "name": "Washington Capitals",
      "teamName": "Capitals",
      "primaryColor": "#041E42",
      "secondaryColor": "#C8102E"
    },
    "16": {
      "abbreviation": "CHI",
      "id": 16,
      "name": "Chicago Blackhawks",
      "teamName": "Blackhawks",
      "primaryColor": "#cf0a2c",
      "secondaryColor": "#111"
    },
    "17": {
      "abbreviation": "DET",
      "id": 17,
      "name": "Detroit Red Wings",
      "teamName": "Red Wings",
      "primaryColor": "#ce1126",
      "secondaryColor": "#fff"
    },
    "18": {
      "abbreviation": "NSH",
      "id": 18,
      "name": "Nashville Predators",
      "teamName": "Predators",
      "primaryColor": "#041e42",
      "secondaryColor": "#ffb81c"
    },
    "19": {
      "abbreviation": "STL",
      "id": 19,
      "name": "St. Louis Blues",
      "teamName": "Blues",
      "primaryColor": "#002f87",
      "secondaryColor": "#fcb514"
    },
    "20": {
      "abbreviation": "CGY",
      "id": 20,
      "name": "Calgary Flames",
      "teamName": "Flames",
      "primaryColor": "#b72b35",
      "secondaryColor": "#faaf19"
    },
    "21": {
      "abbreviation": "COL",
      "id": 21,
      "name": "Colorado Avalanche",
      "teamName": "Avalanche",
      "primaryColor": "#6f263d",
      "secondaryColor": "#236192"
    },
    "22": {
      "abbreviation": "EDM",
      "id": 22,
      "name": "Edmonton Oilers",
      "teamName": "Oilers",
      "primaryColor": "#041E42",
      "secondaryColor": "#FF4C00"
    },
    "23": {
      "abbreviation": "VAN",
      "id": 23,
      "name": "Vancouver Canucks",
      "teamName": "Canucks",
      "primaryColor": "#00205B",
      "secondaryColor": "#00843d"
    },
    "24": {
      "abbreviation": "ANA",
      "id": 24,
      "name": "Anaheim Ducks",
      "teamName": "Ducks",
      "primaryColor": "#F47A38",
      "secondaryColor": "#B9975B"
    },
    "25": {
      "abbreviation": "DAL",
      "id": 25,
      "name": "Dallas Stars",
      "teamName": "Stars",
      "primaryColor": "#006847",
      "secondaryColor": "#8F8F8C"
    },
    "26": {
      "abbreviation": "LAK",
      "id": 26,
      "name": "Los Angeles Kings",
      "teamName": "Kings",
      "primaryColor": "#111111",
      "secondaryColor": "#A2AAAD"
    },
    "28": {
      "abbreviation": "SJS",
      "id": 28,
      "name": "San Jose Sharks",
      "teamName": "Sharks",
      "primaryColor": "#006D75",
      "secondaryColor": "#EA7200"
    },
    "29": {
      "abbreviation": "CBJ",
      "id": 29,
      "name": "Columbus Blue Jackets",
      "teamName": "Blue Jackets",
      "primaryColor": "#002654",
      "secondaryColor": "#ce1126"
    },
    "30": {
      "abbreviation": "MIN",
      "id": 30,
      "name": "Minnesota Wild",
      "teamName": "Wild",
      "primaryColor": "#A6192E",
      "secondaryColor": "#154734"
    },
    "52": {
      "abbreviation": "WPG",
      "id": 52,
      "name": "Winnipeg Jets",
      "teamName": "Jets",
      "primaryColor": "#041E42",
      "secondaryColor": "#004C97"
    },
    "53": {
      "abbreviation": "ARI",
      "id": 53,
      "name": "Arizona Coyotes",
      "teamName": "Coyotes",
      "primaryColor": "#8C2633",
      "secondaryColor": "#e2d6b5"
    },
    "54": {
      "abbreviation": "VGK",
      "id": 54,
      "name": "Vegas Golden Knights",
      "teamName": "Golden Knights",
      "primaryColor": "#B4975A",
      "secondaryColor": "#333f42"
    },
    "55": {
      "abbreviation": "SEA",
      "id": 55,
      "name": "Seattle Kraken",
      "teamName": "Kraken",
      "primaryColor": "#001628",
      "secondaryColor": "#99d9d9"
    }
  };


  const teamImageNames = <{[key: string]: string}>{
    "New Jersey Devils": "1",
    "New York Islanders": "2",
    "New York Rangers": "3",
    "Philadelphia Flyers": "4",
    "Pittsburgh Penguins": "5",
    "Boston Bruins": "6",
    "Buffalo Sabres": "7",
    "Montreal Canadiens": "8",
    "Ottawa Senators": "9",
    "Toronto Maple Leafs": "10",
    "Carolina Hurricanes": "12",
    "Florida Panthers": "13",
    "Tampa Bay Lightning": "14",
    "Washington Capitals": "15",
    "Chicago Blackhawks": "16",
    "Detroit Red Wings": "17",
    "Nashville Predators": "18",
    "St. Louis Blues": "19",
    "Calgary Flames": "20",
    "Colorado Avalanche": "21",
    "Edmonton Oilers": "22",
    "Vancouver Canucks": "23",
    "Anaheim Ducks": "24",
    "Dallas Stars": "25",
    "Los Angeles Kings": "26",
    "San Jose Sharks": "28",
    "Columbus Blue Jackets": "29",
    "Minnesota Wild": "30",
    "Winnipeg Jets": "52",
    "Arizona Coyotes": "53",
    "Vegas Golden Knights": "54",
    "Seattle Kraken": "55"
  };

const seasonDates = {
    '2024-2025': {
      startingTeam: 13,
      startDate: '2024-10-04',
      endDate: '2025-04-17',
    },
    '2023-2024': {
        startingTeam: 54,
        startDate: '2023-10-10',
        endDate: '2024-04-19',
    },
    '2022-2023': {
        startingTeam: 21,
        startDate: '2022-10-11',
        endDate: '2023-04-14',
    },
    '2021-2022': {
        startingTeam: 14,
        startDate: '2021-10-12',
        endDate: '2022-05-01',
    },
    '2020-2021': {
      startingTeam: 14,
      startDate: '2021-01-13',
      endDate: '2021-05-19'
    },
    '2019-2020': {
      startingTeam: 19,
      startDate: '2019-10-02',
      endDate: '2020-03-11'
    }
}

const potentialSeasons = [
      {
        name: '2024-2025',
        code: '2025',
        startDate: '2024-10-04',
        endDate: '2025-04-17',
        previousStanleyCupChamp: 13,
        previousStanleyCupChampName: 'Florida Panthers'
    },
    {
        name: '2023-2024',
        code: '2024',
        startDate: '2023-10-10',
        endDate: '2024-04-19',
        previousStanleyCupChamp: 54,
        previousStanleyCupChampName: 'Vegas Golden Knights'
    },
    {
        name: '2022-2023',
        code: '2023',
        startDate: '2022-10-11',
        endDate: '2023-04-14',
        previousStanleyCupChamp: 21,
        previousStanleyCupChampName: 'Colorado Avalanche',
    },
    {
        name: '2021-2022',
        code: '2022',
        startDate: '2021-10-12',
        endDate: '2022-04-30',
        previousStanleyCupChamp: 14,
        previousStanleyCupChampName: 'Tampa Bay Lightning',
    },
]

const defaultSeason = potentialSeasons[0];
const domain = 'https://in-season-cup-api-v2.onrender.com';
export { 
  domain,
  areSameDate,
  countDays,
  diffDates,
  seasonDates,
  teams,
  normalizeDate,
  serializeData,
  potentialSeasons,
  defaultSeason,
  teamImageNames,
};