/* eslint-disable no-shadow */
import {
  useCallback,
  useEffect,
  useState,
} from 'react';

import GameCard from '../../components/Gamecard';
import css from './index.module.scss';
import Logo from '../../components/Logo';
import {
  usePathToGlory,
  usePotentialCupGames,
} from '../../hooks';

import { Panel } from 'primereact/panel'

import { Divider } from 'primereact/divider';
import { CenteredTitle } from '../../components/Panel';

const gameId = (game: { date: any; home: { id: any; }; }) => (`${game.date}_${game.home.id}`);


const TenDayForecast = ({leagueLookup = {}, scrollTop = true, showEmptyMessage = true} : {scrollTop?: boolean, leagueLookup?: any, showEmptyMessage?:boolean}) => {
  const [daysInFuture, setDaysInFuture] = useState(15);
  const [teamSelected, setTeamSelected] = useState<any>();
  const [gameSelected, setGameSelected] = useState<any>();

  const [windowWidth, setWindowWidth] = useState<number | undefined>(0);
  const [showCol, setShowCol] = useState<boolean>(false);

  const {
    potentialCupSchedule: cupSchedule,
    potentialCupGames,
  }:  {
    potentialCupSchedule: any,
    potentialCupGames: any,
  } = usePotentialCupGames(false, daysInFuture);

  useEffect(() => {
    setWindowWidth(window?.visualViewport?.width);
    window.onresize = () => {
      setWindowWidth(window?.visualViewport?.width)
    }
  }, []);

  useEffect(() => {
    const _showCol = ((windowWidth || 10) < ((Object.keys(cupSchedule).length) * 170) )
    setShowCol(_showCol)
  }, [windowWidth, cupSchedule])

  const {
    importantGames,
    pathToGlory,
  } : {importantGames: any, pathToGlory: any } = usePathToGlory(teamSelected, potentialCupGames);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const clickHandler = useCallback((e:any) => {
    const team = e.target.getAttribute('data-id');
    const gameId = e.target.parentElement.getAttribute('data-id');
    if (scrollTop) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    if (team === teamSelected && gameId === gameSelected) {
      // setTeamSelected(null);
      // setGameSelected(null);
    } else {
      setTeamSelected(team);
      setGameSelected(gameId);
    }
  }, [gameSelected, scrollTop, teamSelected]);
  
  if (daysInFuture > 6 && potentialCupGames?.length < 1) {
    if (showEmptyMessage) {
      return (<p className='m-4'>Season is over</p>);
    }
    return null;
  }

  const onChangeDays = (e: any) => {
    let {
      target: {
        value
      }
    } = e;
    value = parseInt(value);
    if (value) {
      setDaysInFuture(value);
      setTeamSelected(null);
      setGameSelected(null);  
    }
  };

  return (
    cupSchedule
    && (
    <div id='page-container' className='text-center flex flex-col justify-center'>
      <p className='p-4'>
        And now with your {daysInFuture}-day forecast,
        here are the potential cup games over the next {daysInFuture} days.
        Click on a team logo to see what game outcomes need to occur for them to win the cup.
      </p>
        <label htmlFor='futureDays'>Days in Future: {daysInFuture}</label>
        <br />
        <input className='w-64 m-auto' name='futureDays' type='range' step='1' min='1' max='30' value={daysInFuture} onChange={onChangeDays} />
      {teamSelected && <Logo teamId={teamSelected} size="small" onClick={undefined} className='m-auto' />}
      <div className="flex flex-col">
        {pathToGlory && Object.keys(pathToGlory).sort().map((date:any) => {
          const game = pathToGlory[date];
          if (game) {
            return (
              <GameCard
                game={game}
                key={date}
                header={`${game.away.abbrev} @ ${game.home.abbrev}`}
                predictedWinnerId={game.predictedWinner}
                leagueLookup={leagueLookup}
              />
            );
          }
          return null;
        })}
      </div>

      {(!cupSchedule || Object.keys(cupSchedule).length < 1) && <p>No cup games in the next {daysInFuture} days</p>}
      <div id='forecast-container' className={`${css.container} ${showCol ? 'flex-col items-center' : 'flex-row'}`}>
        {
        Object.keys(cupSchedule).map((date:any) => {
          const games = cupSchedule[date];
          return (
            <Panel key={`forecast-${date}`} header={date} className='grow m-4 max-w-[400px]' headerTemplate={CenteredTitle}>
              {games.map((cupGame:any, i:number) => (
                <div key={`forecast-${gameId(cupGame)}`}>
                  <div data-id={`${gameId(cupGame)}`} className={`flex flex-row items-center justify-between ${importantGames[gameId(cupGame)] ? 'bg-gray-200' : ''}`}>
                    <div className={`bg-${leagueLookup[cupGame.away.id]?.color}-400`}>
                      <Logo size='small' onClick={clickHandler} className='cursor-pointer' teamId={cupGame.away.id} />
                    </div>
                    @
                    <div className={`bg-${leagueLookup[cupGame.home.id]?.color}-400`}>
                      <Logo size='small' onClick={clickHandler} className='cursor-pointer' teamId={cupGame.home.id} />
                    </div>
                  </div>
                  {i < games.length - 1 &&
                    <Divider layout="horizontal" type='dotted' className='bg-gray-400' style={{height: '2px'}} />
                  }
                </div>
              ))}
            </Panel>
          );
        })
      }
      </div>
    </div>
    )
  );
};

export default TenDayForecast;
