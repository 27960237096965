import { useStoreState } from "../../store/hooks";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import LeagueNameLink from "./Link";
import { useEffect, useState } from "react";
import axios from "axios";
import { domain } from "../../util";
import { getLeagues } from "../../axios";

const LeagueTable = () => {
    const currentSeasonId = useStoreState(state => state.currentSeasonId);
    const [leagues, setLeagues] = useState([]);
    useEffect(() => {
        if (currentSeasonId) {
            Promise.all([
                getLeagues(currentSeasonId)
            ]).then(([leagueData]) => {
                setLeagues(leagueData.data.responseObject);
            }).catch(error => {
                console.log(error);
            })
        }
    }, [currentSeasonId]);

    return(<>
        <DataTable value={leagues} className='mt-4 text-center' header={'Leagues'}>
            <Column field='leagueId' header="Name" body={LeagueNameLink}></Column>
            <Column field="metadata.commissioner" header="Commissioner"></Column>
        </DataTable>
    </>)

};

export default LeagueTable;