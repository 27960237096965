import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Link, Outlet, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { Season } from './season';
import Layout from './Layout';
import Draft from './pages/draft';
import { useStoreState } from './store/hooks';
import Calendar from './pages/calendar';
import League from './pages/league';
import LeagueTable from './pages/league/table';
import TenDayForecast from './pages/forecast';

const NoMatch = () => {
  return <div>Page Not Found - 404</div>
}

function App() {
  return (
    <Router>
      <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Calendar />}/>
            <Route path="/season/:seasonId/calendar" element={<Calendar />}/>
            <Route path="/season/:seasonId/draft" element={<Draft />}/>
            <Route path="/season/:seasonId/league/:leagueId" element={<League />}/>
            <Route path="/season/:seasonId/league" element={<LeagueTable />} />
            <Route path="/season/:seasonId/forecast" element={<TenDayForecast />} />
            {/* Using path="*"" means "match anything", so this route
                  acts like a catch-all for URLs that we don't have explicit
                  routes for. */}
            <Route path="*" element={<NoMatch />} />
          </Route>
      </Routes>
    </Router>
  );
}

export default App;
