const seasonMetadata = [
    {
        name: '2024-2025',
        code: '2025',
        startDate: '2024-10-04',
        endDate: '2025-04-17',
        previousStanleyCupChamp: 13,
        previousStanleyCupChampName: 'Florida Panthers',
        holidays: [{
            startDate: '2024-12-24',
            endDate: '2024-12-26',
            name: 'Christmas Break',
            icon: 'gift'
        },
        ]
    },
    {
        name: '2023-2024',
        code: '2024',
        startDate: '2023-10-10',
        endDate: '2024-04-19',
        previousStanleyCupChamp: 54,
        previousStanleyCupChampName: 'Vegas Golden Knights',
        holidays: [{
            startDate: '2023-12-24',
            endDate: '2023-12-26',
            name: 'Christmas Break',
            icon: 'gift'
        },
        {
            startDate: '2024-02-01',
            endDate: '2024-02-04',
            name: 'All Star Break',
            icon: 'trophy'
        },
        {
            startDate: '2024-04-19',
            endDate: '2024-04-19',
            name: 'Season Over',
            icon: 'trophy'
        }
    ]
    },
    {
        name: '2022-2023',
        code: '2023',
        startDate: '2022-10-11',
        endDate: '2023-04-14',
        previousStanleyCupChamp: 21,
        previousStanleyCupChampName: 'Colorado Avalanche',
        holidays: []
    },
    {
        name: '2021-2022',
        code: '2022',
        startDate: '2021-10-12',
        endDate: '2022-05-01',
        previousStanleyCupChamp: 14,
        previousStanleyCupChampName: 'Tampa Bay Lightning',
        holidays: []
    },
    {
        name: '2020-2021',
        code: '2021',
        startDate: '2021-01-13',
        endDate: '2021-05-19',
        previousStanleyCupChamp: 14,
        previousStanleyCupChampName: 'Tampa Bay Lightning',
        holidays: []
    }
];

exports.metadata = seasonMetadata;