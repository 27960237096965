import React, { useState, useEffect } from 'react';
import { OrderList } from 'primereact/orderlist';
import { Panel, PanelToggleEvent } from 'primereact/panel';
import { useStoreState } from '../../store/hooks';
import axios from 'axios';
import Calendar from '../calendar';
import { Button } from 'primereact/button';
import { teams as _teams, domain } from '../../util';
import TenDayForecast from '../../components/Forecast';
import Logo from '../../components/Logo';
import { useParams } from 'react-router-dom';
import { getSeasonData } from '../../axios';

const PanelHeaderTemplate = (options:any) => {
    const {
        className,
        props: {
            header,
            content
        },
    } = options;

    return (<div className={className}>{header}<div className={`bg-${content}-400`} style={{width: '20px', height: '20px'}}></div></div>)
};

const TeamPanelTemplate = ({
        teams,
        name,
        teamTotals,
        collapsed,
        onToggle,
        color
    }:
    {
        teams: any,
        name: string,
        teamTotals: any,
        collapsed: boolean,
        onToggle: any,
        color: string
    }) => {
    let total = 0;
    let wins = 0;
    let losses = 0;
    const { seasonId } = useParams();

    if (!seasonId || Object.keys(teamTotals).length < 1) {
        return null;
    }
    teams.forEach((team: string) => {
        total += teamTotals[seasonId][team].days;
        wins += teamTotals[seasonId][team].wins;
        losses += teamTotals[seasonId][team].losses;
    });


    const teamMappings:any = _teams;

    return (<Panel onToggle={onToggle} className='card w-60' headerTemplate={PanelHeaderTemplate} collapsed={collapsed} header={`${name} (${wins}-${losses}) - ${total} days`} content={color} toggleable>
        <ul>
            {teams.map((team:string) => (
                <li key={`${team}_${name}`}><div className='flex justify-center items-center py-2'><Logo teamId={parseInt(team)} size='extra-small' /> ({teamTotals[seasonId][team]?.wins}-{teamTotals[seasonId][team]?.losses}) - {teamTotals[seasonId][team]?.days} days</div></li>
            ))}
        </ul>
    </Panel>);
};

const League = () => {
    const { leagueId = '', seasonId } = useParams();
    const teamTotals = useStoreState(state => state.teamTotals);
    const [leagueRosters, setLeagueRosters] : [leagueRosters: any, setLeagueState: any] = useState();
    const [leagueLookup, setLeagueLookup] = useState();
    const [collapsed, setCollapsed] = useState(true);
    useEffect(() => {
        if (leagueRosters && Object.keys(teamTotals).length) {
            //TODO - DELETE - hack for BEDlaM
            if (leagueId === '2' && teamTotals && seasonId) {
                teamTotals[seasonId][13].days = teamTotals[seasonId][13]?.days - 4;
            }
            const reverseLookup: any = {};
            leagueRosters.participants.forEach((participant:any) => {
                participant.teams.forEach((teamName: string) => {
                    reverseLookup[teamName] = {
                        name: participant.username,
                        color: participant.color,
                    };
                })
            });
            setLeagueLookup(reverseLookup)            
        }
    }, [teamTotals, leagueRosters]);

    useEffect(() => {
        if (seasonId) {
            Promise.all([
                getSeasonData(leagueId, seasonId)
            ]).then(([leagueData]) => {
                setLeagueRosters(leagueData?.data?.responseObject);
            }).catch(error => {
                console.log(error);
            })
        }
    }, [seasonId, leagueId]);
    
    return (<div className='flex flex-col items-center mt-4'>
        <Button  label={collapsed ? 'Expand' : 'Collapse'} severity="info" onClick={() => (setCollapsed(!collapsed))} />
        <div className="grid tablet:grid-cols-2 desktop:grid-cols-4 gap-4 m-4">
            {leagueRosters && leagueRosters?.participants?.map((participant: any) => (
                <TeamPanelTemplate
                    color={participant.color}
                    collapsed={collapsed}
                    teamTotals={teamTotals}
                    onToggle={(e: PanelToggleEvent) => {setCollapsed(e.value)}}
                    key={participant.username}
                    teams={participant.teams}
                    name={participant.teamName || participant.username}
                /> 
            ))}
        </div>
        <Calendar leagueId={parseInt(leagueId || '')} leagueLookup={leagueLookup} />
        <TenDayForecast scrollTop={false} leagueLookup={leagueLookup} showEmptyMessage={false} />
    </div>)
}

export default League;