/* eslint-disable max-len */
import css from './index.module.scss';
import { teamImageNames } from '../../util';
import React from 'react';

const Logo = (props: { teamId?: number; size: any; className?: string | undefined; onClick?: any; disabled?: boolean }) => {
  const {
    teamId,
    size,
    className,
    onClick,
    disabled,
  } = props;

  if (!teamId) {return null;}
  let classes = css[size] || '';
  classes += ` ${className}`;

  if (disabled) {
    classes += ` ${css.disabled}`
  }
  if (onClick && !disabled) {
    classes += ` ${css.clickable}`
  }
  const _onClick = disabled ? () => {} : onClick;

  type styleType = {height?: string, width?:string};

  let style = {width: '100%', height: 'auto'} as styleType;

  if (size) {
    style = {
      height: 'inherit'
    }
  }
  // eslint-disable-next-line consistent-return, jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
  return (
    <img 
        data-id={teamId}
        onClick={_onClick}
        className={classes}
        src={`/teams/${teamId}.svg`}
        alt={`Team ${teamId}`}
        width={0}
        height={0}
        sizes="100vw"
        style={style}
    />);
};

export default Logo;
