import { ProgressSpinner } from "primereact/progressspinner"
import { useStoreState } from "./store/hooks";
export const Loading = ({message}: {message:string}) => {
    const isLoading = useStoreState(state => state.isLoading);
    if (!isLoading) {
        return <></>;
    } 
    return (<div className='bg-transparent absolute z-10 flex justify-center items-center'>
            <div className='w-screen h-screen bg-gray-200 opacity-50'></div>

            <ProgressSpinner className='absolute' />
        </div>
    )
}