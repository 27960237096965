import { domain } from "../util";

import axios from 'axios';

const axios_instance = axios.create({
    baseURL: domain,
    headers: {
        'Content-Type': 'application/json'
    }
});

console.log('creating')
function refresh_token() {
    console.log('Refreshing access token')
    return axios.get(`${domain}/user/refresh`);
}

let refreshing_token:any = null;

axios_instance.interceptors.response.use((response:any) => {
    return response
}, async (error:any) => {
    const config = error.config;
    if(error.response && error.response.status === 401 && !config._retry) {
        config._retry = true;
        try {
            refreshing_token = refreshing_token ? refreshing_token : refresh_token();
            await refreshing_token;
            return axios_instance(config);
        } catch (err) {
            return Promise.reject(err)
        }
    }
    return Promise.reject(error)
});

export const getLeagues = (currentSeasonId: string) => {
  return axios_instance.get(`${domain}/league/season/${currentSeasonId}`);
};

export const getSeasonData = (leagueId:string, seasonId:string) => {
  return axios_instance.get(`${domain}/league/${leagueId}/season/${seasonId}`);
};

export const getSeasonResults = (seasonId:string) => {
  return axios_instance.get(`${domain}/results/season/${seasonId}`);
};

export default axios_instance;