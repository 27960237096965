import { useMemo } from 'react';
import { useStoreState } from '../store/hooks';
import moment from 'moment';

const usePotentialCupGames = (entireSeason = false, days = 10) => {
  const seasons = useStoreState(state => state.seasons);
  const potentialSeasons = useStoreState(state => state.potentialSeasons);
  const currentSeasonId = useStoreState(state => state.currentSeasonId);
  const seasonResults = useStoreState(state => state.seasonResults);

  return useMemo(() => {
    const events = seasonResults[currentSeasonId];
    const schedule = seasons[currentSeasonId];
    let mostRecentGame;
    let lastChangeEventInSchedule = 0;
    
    if (events?.length) {
      for(let i = events.length; i >= 0; i--) {
        const event = events[i];
        if (event?.loser) {
          mostRecentGame = event;
          i = 0;
        }
      }
    }

    const currentChamp = mostRecentGame?.currentChamp ? mostRecentGame.currentChamp : potentialSeasons.find(season => season.code === currentSeasonId)?.previousStanleyCupChamp;
    
    if (mostRecentGame) {
      schedule?.forEach((game, index) => {
        const {
          date,
          home,
          away,
        } = game;
        
        if (date === mostRecentGame.date) {
          if (currentChamp === home.id || currentChamp === away.id) {
            lastChangeEventInSchedule = index + 1;
          }
        }
      });
    }

    const potentialChamps = {};
    potentialChamps[currentChamp] = true;
    
    let potentialCupGames = [];
    let potentialCupSchedule = {};
    
    if (currentSeasonId !== '2025') {
      return {
        potentialCupGames,
        potentialCupSchedule
      }
    }

    let endDate = schedule ? schedule[schedule?.length - 1].date : '';

    if (entireSeason && schedule) {
      endDate = schedule[schedule.length - 1].date;
    } else if (days) {
      if (mostRecentGame) {
        endDate = moment(mostRecentGame?.date).add(days, 'days');
      } else if (schedule) {
        endDate = moment(schedule[0].date).add(days, 'days');
      }
    }

    for (let i = lastChangeEventInSchedule; i < schedule?.length; i++) {
      const game = schedule[i];
      const {
        date,
        home,
        away,
      } = game;

      if ((potentialChamps[home.id] || potentialChamps[away.id]) && moment(date).diff(endDate) < 0) {
        potentialCupGames.push(game);
        if (potentialCupSchedule[date]) {
          potentialCupSchedule[date].push(game)
        } else {
          potentialCupSchedule[date] = [game];
        }
        potentialChamps[away.id] = true;
        potentialChamps[home.id] = true;
      }
    }

    return {
      potentialCupGames,
      potentialCupSchedule,
    }
  }, [seasonResults, currentSeasonId, seasons, potentialSeasons, entireSeason, days]);
};

export default usePotentialCupGames;
