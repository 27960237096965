import { useRef, useState } from "react";
import { teams, TeamsObject } from "../../constants";
import Logo from "../../components/Logo";
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext'
import { Panel } from "primereact/panel";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Dialog } from "primereact/dialog";


// Note - Make generic - these 2 components are used elsewhere
const PanelHeaderTemplate = (options:any) => {
    const {
        className,
        props: {
            header,
            content
        },
    } = options;

    return (<div className={className}>{header}<div className={`bg-${content}-400`} style={{width: '20px', height: '20px'}}></div></div>)
};
const TeamPanelTemplate = ({
    teams,
    name,
    color
}:
{
    teams: any,
    name: string,
    color: string
}) => {

    return (<Panel className='card w-60 m-4' headerTemplate={PanelHeaderTemplate}  header={`${name}`} content={color}>
        <ul>
            {teams.map((team:string) => (
                <li key={`${team}_${name}`}><div className='flex justify-center items-center py-2'><Logo teamId={parseInt(team)} size='extra-small' /></div></li>
            ))}
        </ul>
    </Panel>);
};


const Draft = ({seasonId}: {seasonId?: string}) => {
    const [draftResults, setDraftResults] = useState<any>([]);
    const [draftReady, setDraftReady] = useState(false);
    const [draftOrder, setDraftOrder] = useState<any>([]);
    const [visible, setVisible] = useState(false);
    const [participant, setParticipant] = useState<any>();
    const [participantName, setParticipantName] = useState<any>('');
    const [leagueName, setLeagueName] = useState<any>('');
    const [selectedTeam, setSelectedTeam] = useState<any>();
    const [teamsSelected, setTeamsSelected] = useState<any>({});

    const confirm = () => {
        confirmDialog({
            message: 'Are you sure you want to start the draft?',
            header: 'Draft Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-info',
            accept,
            reject
        });
        let sign = 1;
        let order = [];
        const numTeams = draftResults.length;
        for(let i = 0; i < 32; i++) {
            let number = 0 + i%numTeams;
            if (sign < 0) {
                number =  (numTeams - 1) + (i%numTeams * -1)
            }
            if (i%numTeams === (numTeams - 1)) {
                sign = -1 * sign;
            }
            order.push(number)
        }
        setDraftOrder(order);
    };
    const toast = useRef<any>();

    const selectTeam = (e: any) => {
        setSelectedTeam(e.target.getAttribute('data-id'))
        setParticipant(draftResults[draftOrder[0]])
        setVisible(true);
    };

    const accept = () => {
        toast.current.show({ severity: 'info', summary: 'Draft', detail: 'Starting Draft Now', life: 3000 });
        setDraftReady(true);
    }

    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Draft', detail: 'Not Starting Draft Yet', life: 3000 });
    }

    const confirmPick = (e:any) => {
        draftResults[draftOrder[0]].teams = [...draftResults[draftOrder[0]].teams, selectedTeam];
        draftOrder.shift();
        const _selected = Object.assign(teamsSelected);
        _selected[selectedTeam] = true;
        setTeamsSelected(_selected);
        setVisible(false);
    };

    const exportData = () => {
        const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
            JSON.stringify({participants: draftResults})
        )}`;
        const link = document.createElement("a");
        link.href = jsonString;
        link.download = `${leagueName}.json`;
    
        link.click();
    };

    return (
        <div>
            <Dialog
                visible={visible}
                modal
                onHide={() => {if (!visible) return; setVisible(false); }}
                header={<div className='text-center'>Confirm Pick</div>}
                closable={false}
            >
                <div className="flex flex-col px-8 py-5 gap-4 justify-center items-center">
                    <Logo teamId={selectedTeam} size='medium' />
                    <div className='w-full m-4'>
                        {`Would you like to add ${teams[selectedTeam]?.name} to ${participant?.username}'s lineup?`}
                    </div>
                    <div className="flex flex-row justify-evenly w-full">
                        <Button label="Cancel" onClick={(e) => setVisible(false)} severity='danger'></Button>
                        <Button autoFocus label="Confirm Pick" onClick={confirmPick} severity='info'></Button>
                    </div>
                </div>
            </Dialog>
            <Toast ref={toast} />
            <ConfirmDialog />
            {draftReady && <h2 className='text-center text-xl m-4'>League Draft: {leagueName}</h2>}

            <div className='flex flex-row flex-wrap m-4 min-h-40 justify-center'>
                {draftResults.map((team:any) => {
                    return (<TeamPanelTemplate
                        color={team.color}
                        key={team.username}
                        teams={team.teams}
                        name={team.username}
                        /> )
                    })}
            </div>
            
            {!draftReady &&
                <div className='m-4 sm:m-8 flex flex-col items-stretch tablet:items-center justify-center'>
                    <p className='m-4'>Enter your team names in draft order. By default drafting order is a snake draft so the team with the last pick of the previous round gets the first pick of the next round</p>
                    <InputText className='m-4 tablet:w-96' placeholder='League Name' type='text' value={leagueName} onChange={(e) => {setLeagueName(e.target.value)}} />
                    <form className='m-4 flex flex-row tablet:w-96' onSubmit={(e) => {e.preventDefault();}}>
                        <InputText className='grow' placeholder='Elliot Friedman' type='text' value={participantName} onChange={(e) => {setParticipantName(e.target.value)}} />
                        <Button 
                            onClick={(e) => {
                                if (participantName) {
                                    if (draftResults.find(({username}:any) => username === participantName)) {
                                        return toast.current.show({ severity: 'error', summary: 'Draft', detail: 'Username is already taken for this draft', life: 3000 });
                                    }
                                    const color = ['yellow', 'fuchsia', 'lime', 'sky', 'pink', 'red', 'orange', 'amber', 'cyan', 'violet'][draftResults.length];
                                    setDraftResults([...draftResults, {
                                        username: participantName,
                                        color,
                                        teams: []
                                    }])
                                    return setParticipantName('');
                                }
                            }} 
                            severity='info'
                            label="Add Team"
                            >
                        </Button>
                    </form>
                    <Button className='m-4' label='Start Draft' disabled={draftResults.length < 2 || leagueName === ''} onClick={confirm}></Button>
                </div>
            }

            {draftReady && 
                <>
                    <div className='flex flex-row flex-wrap m-8 space-evenly justify-center'>
                        {draftOrder.length !== 0 && 
                            <Panel className='w-60 m-8' header='Currently Drafting'>
                                {draftResults[draftOrder[0]].username}
                            </Panel>
                        }
                        {draftOrder.length >= 2 &&
                            <Panel className='w-60 m-8' header='Up Next'>
                                {draftOrder.length && draftResults[draftOrder[1]].username}
                            </Panel>
                        }
                    </div>
                    <div className='flex flex-row flex-wrap justify-center sm:m-8'>
                        {(Object.keys(teams)).filter(teamId => (!teamsSelected[teamId])).map(teamId => {
                            if (teamId !== '53') {
                                return <div key={teamId} className='my-4'><Logo disabled={teamsSelected[teamId]} onClick={selectTeam} teamId={parseInt(teamId)} size={'extra-large'}/></div>
                            }
                        })}
                    </div>
                    {draftOrder.length === 0 &&
                    <div className='flex items-center justify-center flex-col'>
                        <Button severity='info' onClick={exportData}>Download Draft Results</Button>
                    </div>
                    }
                </>
            }
        </div>
        
    )
};

export default Draft;