import moment from 'moment';
import css from './index.module.scss';
import Logo from '../Logo';
import { Panel } from 'primereact/panel';
import { classNames } from 'primereact/utils';
import { Game } from '../../types';
import { CenteredTitle } from '../Panel';

const GameCard = ({
  game,
  clickHandler = undefined,
  header,
  predictedWinnerId,
  allActive,
  leagueLookup = {},
}: {
  game: Game,
  clickHandler?: Function,
  header: string,
  predictedWinnerId?:number,
  allActive?:boolean,
  leagueLookup?: any,
}) => {

  if(!game) return null;

  const {
    away: {
      id: awayId,
    },
    home: {
      id: homeId
    },
    date,
    winnerId,
    startTimeUTC,
  } = game;

  const awayWin = [winnerId, predictedWinnerId].includes(awayId);
  const homeWin = [winnerId, predictedWinnerId].includes(homeId);
  return (<Panel header={header} headerTemplate={CenteredTitle} key={date} className={'m-4 ' + css.game}>
    <div className='flex flex-col items-center'>
      <div className='flex flex-row items-center justify-center'>
        <Logo
          size="medium"
          className={classNames({
            [css.logo]: true,
            [css.active]:  awayWin || allActive,
            // [css.highlight]: awayWin,
            ['cursor-pointer']: clickHandler,
            [`bg-${leagueLookup[awayId]?.color}-400`]: awayWin
          })}
          onClick={clickHandler}
          teamId={awayId}
          />
        @
        <Logo
          size="medium"
          className={classNames({
            [css.logo]: true,
            [css.active]: homeWin || allActive,
            // [css.highlight]: homeWin,
            ['cursor-pointer']: clickHandler,
            [`bg-${leagueLookup[homeId]?.color}-400`]: homeWin
          })}
          onClick={clickHandler}
          teamId={homeId}
          />
      </div>
      <div className='pt-4'>{moment(startTimeUTC).format('LLL')}</div>
      </div>

  </Panel>);
};

export default GameCard;
