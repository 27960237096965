const includesTeam = (game, teamId) => {
  return game.away.id === parseInt(teamId) || game.home.id === parseInt(teamId)
};

export const findOpponent = ({away, home}, teamId) => {
  if (teamId === away.id) {
    return home.id;
  } else if (teamId === home.id) {
    return away.id;
  }
}

const gameId = (game) => {
  if (game) {
    return `${game.date}_${game.home.id}`;    
  }

};

const usePathToGlory = (teamId, potentialCupGames) => {
  // Find first game this team played in
  if (!potentialCupGames || teamId === undefined || teamId === null) {
    return {
      pathToGlory: {},
      importantGames: {},
    };
  }

  teamId = parseInt(teamId);

  const index = potentialCupGames.findIndex((cupGame) => (includesTeam(cupGame, teamId)));
  const finalGame = potentialCupGames[index];
  const importantGames = { [gameId(finalGame)]: true };
  const pathToGlory = {
    [finalGame?.date]: {
      ...finalGame,
      predictedWinner: teamId,
    },
  };
  
  if (index >= 0) {
    let opponent1 = findOpponent(potentialCupGames[index], teamId);
    let opponent2;
    for (let i = index - 1; i >= 0; i--) {
      const game = potentialCupGames[i];
      if (includesTeam(game, opponent1)) {
        const predictedWinner = opponent1;
        pathToGlory[game.date] = {
          ...game,
          predictedWinner,
        };
        opponent2 = findOpponent(game, opponent1);
        importantGames[gameId(game)] = true;
      } else if (includesTeam(game, opponent2)) {
        const predictedWinner = opponent2;
        pathToGlory[game.date] = {
          ...game,
          predictedWinner,
        };
        importantGames[gameId(game)] = true;
      }
    }
  }

  return {
    importantGames,
    pathToGlory,
  };
};

export default usePathToGlory;
